import axios from "axios";

import useToast from "@hooks/useToast";
import { useNavigate } from 'react-router-dom'
import { useContext, useState } from "react";
import moment from "moment"

function useApi() {
	// Toast нэмэх функц
	const addToast = useToast()
	const navigate = useNavigate()
	const [token, setToken] = useState(localStorage.getItem("token") || '')

	const baseURL = process.env.REACT_APP_SERVER_URL+process.env.REACT_APP_API_VERSION || ''
	const source = axios.CancelToken.source();
	const instance = axios.create({
		baseURL: baseURL,
		cancelToken: source.token,
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			'Authorization': `Bearer ${token}`
		},
	});


	instance.interceptors.request.use((req) => {
		req.headers.Authorization = `Bearer ${token}`;
		return req;
	})



	/*
		Response дата
	*/
	instance.interceptors.response.use(
		function (rsp) {
			const data = rsp?.data;
			const text = data?.info

			// хүсэлт амжилттай болоод info ирэх үед
			if (data?.success && text) {
				addToast(
					{
						type: 'success',
						text: text
					}
				)
			}
			else if (!data?.success) {
				// Toast дээр error харуулах
				const err_message = data.error
				if (err_message) {
					addToast(
						{
							type: 'error',
							text: err_message
						}
					)
				}
				// / Toast дээр warn харуулах
				console.log(data)
				const warn_message = data.warn
				if (warn_message) {
					addToast(
						{
							type: 'warning',
							text: warn_message
						}
					)
				}
			}
			return data

		},
		function (error) {
			var err = error?.response?.data
			var status_code = error?.response?.status
			// Token
			if (err?.refresh) {
				navigate('/login')
				return err
			}

			if (err) {
				if ([403, 401].indexOf(status_code) !== -1) {
					navigate('/login')
				} else {
					addToast(
						{
							text: 'Алдаа гарлаа. Системийн админд хандана уу.',
							type: 'error',
							style: {
								padding: '16px',
								border: `1px solid #EB544C`
							}
						})
				}
				return err
			}
			else {
				return Promise.reject(err);
			}
		}
	);

	return {
		core: {
			// Аймгийн мэдээлэл
			aimag: {
				get: () => instance.get(`/aimag`),
			},

			// Сумын мэдээлэл
			sum: {
				get: (aimag) => instance.get(`/sum?aimag=${aimag}`),
			},

			// Багийн мэдээлэл
			bag: {
				get: (sum) => instance.get(`/bag?sum=${sum}`),
			},

			getGeom: (id, config) => instance.get(`/get-geom/${id}?config=${config}`),
		},

		//  Хэрэглэгчийн мэдээлэл
		user: {
			get: (limit, page, sort, search) => instance.get(`/user?page=${page}&limit=${limit}&sorting=${sort}&search=${search}`),
			post: data => instance.post('/user', data),
			getOne: (pk) => instance.get(`/user/${pk}/`),
			put: (data, pk) => instance.put(`/user/${pk}/`, data),

			new_user_password: (token, data) => instance.post(`/user/new-user-password/${token}`, data),
			logged: () => instance.get('/user/logged'),
			login: (data) => instance.post(`/user/login`, data),
			logout: () => instance.get(`/user/logout`),
			forgotPassword: (data) => instance.post(`/user/forgot-password`, data),

		},

		point: {
			get: () => instance.get(`/point`),
			post: data => instance.post(`/point`, data),
			delete: (top, point) => instance.delete(`/point?point=${point}&topology=${top}`),
			deleteTop: (top) => instance.delete(`/point/top?topology=${top}`),
			fight: (top) => instance.get(`/point/top?topology=${top}`)
		},

		border: {
			get: () => instance.get(`/border`),
			post: data => instance.post(`/border`, data),
			delete: (id) => instance.delete(`/border/${id}/`)
		},

		mapclear: {
			delete: () => instance.delete(`/alldelete`)
		},
		salbarPost: {
			getList: () => instance.get(`/salbar-post`),
			getAllList: () => instance.get(`/salbar-post/list`),
			getOptionList: () => instance.get(`/salbar-post/all`),
			post: data => instance.post(`/salbar-post`, data),
			put: (data, pk) => instance.put(`/salbar-post/${pk}`, data),
			delete: (pk) => instance.delete(`/salbar-post/${pk}`),
			getGeom: () => instance.get(`/salbar-geom`),

			getDetail: (pk) => instance.get(`/salbar-post/${pk}`),
			getWeather: () => instance.get(`/salbar-post/weather`),

			postStants: (data, pk) => instance.post(`/salbar-post/stants/${pk}`, data)
		},

		group: {
			get: (depth = 0, type) => instance.get(`/group/list?depth=${depth}&type=${type}`),
			post: data => instance.post(`/group/list/`, data),
			getOne: (pk) => instance.get(`/group/list/${pk}`),
			put: (data, pk) => instance.put(`/group/list/${pk}`, data),
			delete: (pk) => instance.delete(`/group/list/${pk}`),
		},

		label: {
			get: (limit, page, sort, search) =>
				instance.get(`/group/tactic-label?limit=${limit}&page=${page}&sorting=${sort}&search=${search}`),
			getList: () => instance.get(`/group/tactic-label/list`),
			post: data => instance.post(`/group/tactic-label`, data),
			getOne: (pk) => instance.get(`/group/tactic-label/${pk}`),
			put: (data, pk) => instance.put(`/group/tactic-label/${pk}`, data),
			delete: (pk) => instance.delete(`/group/tactic-label/${pk}`),
		},
		stantsSign: {
			get: (limit, page, sort, search) =>
				instance.get(`/group/stants-label?limit=${limit}&page=${page}&sorting=${sort}&search=${search}`),
			getList: () => instance.get(`/group/stants-label/list`),
			post: data => instance.post(`/group/stants-label`, data),
			getOne: (pk) => instance.get(`/group/stants-label/${pk}`),
			put: (data, pk) => instance.put(`/group/stants-label/${pk}`, data),
			delete: (pk) => instance.delete(`/group/stants-label/${pk}`),
		},
		stants: {
			get: (limit, page, sort, search) =>
				instance.get(`/group/stants?limit=${limit}&page=${page}&sorting=${sort}&search=${search}`),
			getList: () => instance.get(`/group/stants/list`),
			post: data => instance.post(`/group/stants`, data),
			getOne: (pk) => instance.get(`/group/stants/${pk}`),
			put: (data, pk) => instance.put(`/group/stants/${pk}`, data),
			delete: (pk) => instance.delete(`/group/stants/${pk}`),
		},
		bvs: {
			get: (limit, page, sort, search) =>
				instance.get(`/group/bvs?limit=${limit}&page=${page}&sorting=${sort}&search=${search}`),
			getList: () => instance.get(`/group/bvs/list`),
			getGeom: () => instance.get(`/group/bvs/geom`),
			post: data => instance.post(`/group/bvs`, data),
			getOne: (pk) => instance.get(`/group/bvs/${pk}`),
			put: (data, pk) => instance.put(`/group/bvs/${pk}`, data),
			delete: (pk) => instance.delete(`/group/bvs/${pk}`),
		},
		time: {
			get: (limit, page, sort, search) =>
				instance.get(`/group/time?limit=${limit}&page=${page}&sorting=${sort}&search=${search}`),
			getList: () => instance.get(`/group/time/list`),
			post: data => instance.post(`/group/time`, data),
			getOne: (pk) => instance.get(`/group/time/${pk}`),
			put: (data, pk) => instance.put(`/group/time/${pk}`, data),
			delete: (pk) => instance.delete(`/group/time/${pk}`),
		},

		schedule: {
			post: (user_id, start, end) => instance.post(`/schedule/estimate?start=${start}&end=${end}`, user_id),
			get: (start, end) => instance.get(`/schedule?start=${start}&end=${end}`),
			getTk: (start, end) => instance.get(`/schedule/tk?start=${start}&end=${end}`),
			getKomand: (start, end) => instance.get(`/schedule/estimate?start=${start}&end=${end}`),
			setTimetable: (datas) => instance.post(`/schedule/habituate`, datas),
			getTimetable: () => instance.get(`/schedule/habituate`),
			delete: (pk) => instance.delete(`/schedule/habituate/${pk}`),

			// Төлөвлөгөөт бусаар салбар зогсоох
			stopEvent: (data) => instance.post('/schedule/not-plan/stop', data),
			// Төлөвлөгөөт бусаар салбар ажиллуулах
			workEvent: (data) => instance.post('/schedule/not-plan/work', data),

			getReport: (start, end) => instance.get(`/schedule/report?start=${start}&end=${end}`)

		},
		morse: {
			get: () => instance.get(`/morse`),
			getOne: (pk) => instance.get(`/morse/${pk}`),
			post: data => instance.post(`/morse`, data),
			put: (pk, data) => instance.put(`/morse/${pk}`, data),
			delete: (pk) => instance.delete(`/morse/${pk}`),
			transaction: (pk, data, type) => instance.put(`/morse/transaction/${pk}?type=${type}`, data),
			getBySalbar: (pk) => instance.get(`/morse/by-salbar/${pk}`),
		},

		position: {
			get: (limit, page, sort, search) => instance.get(`/position?limit=${limit}&page=${page}&sorting=${sort}&search=${search}`),
			post: data => instance.post(`/position/`, data),
			put: (data, pk) => instance.put(`/position/${pk}`, data),
			delete: (pk) => instance.delete(`/position/${pk}`),
		},

		degree: {
			get: (limit, page, sort, search) => instance.get(`/position/degree/list?limit=${limit}&page=${page}&sorting=${sort}&search=${search}`),
			getAll: () => instance.get(`/position/degree`),
			post: data => instance.post(`/position/degree`, data),
			put: (data, pk) => instance.put(`/position/degree/${pk}`, data),
			delete: (pk) => instance.delete(`/position/degree/${pk}`),
		},
		employee: {
			getAll: () => instance.get(`/employee`),
			post: data => instance.post(`/employee`, data),
			put: (data, pk) => instance.put(`/employee/${pk}`, data),
			delete: (pk) => instance.delete(`/employee/${pk}`),
			checkRegister: (data) => instance.post(`/employee/check-register`, data)
		},
		signal: {
			get: (limit, page, sort, search) => instance.get(`/signal/?limit=${limit}&page=${page}&sorting=${sort}&search=${search}`),
			post: data => instance.post(`/signal/`, data),
			put: (data, pk) => instance.put(`/signal/${pk}`, data),
			delete: (pk) => instance.delete(`/signal/${pk}`),
			getList: () => instance.get('/signal/list')
		},
		target: {
			get: (salbar = '', code = '') => instance.get(`/target/?salbar=${salbar ? salbar : ''}&code=${code}`),
			getInfo: (id, code = '') => instance.get(`/target/list/info/${id}?code=${code}`),
			post: (data) => instance.post('/target/', data),
			deleteTarget: (target_number, code) => instance.delete(`/target/${target_number}/?code=${code}`),
			deleteOneTarget: (target_number, code) => instance.delete(`/target/one/${target_number}/?code=${code}`),
			postInfo: (data, id) => instance.put(`/target/${id}`, data),

			// ?salbar_code=${salbar_code}&target_code=${target_code}
			getListByDate: (date = moment(new Date()).format("YYYY-MM-DD")) => instance.get(`/target/list?date=${date}`)
		},
		weather: {
			getByDate: (date, salbar_code) => instance.get(`/weather-info/by-date?date=${date}&salbar_code=${salbar_code}`),
			post: (data) => instance.post(`/weather-info/`, data)
		},
		chemistry: {
			getByDate: (date, salbar_code) => instance.get(`/chemistry-info/by-date?date=${date}&salbar_code=${salbar_code}`),
			post: (data) => instance.post(`/chemistry-info/`, data)
		},
		radiation: {
			getByDate: (date, salbar_code) => instance.get(`/radiation-info/by-date?date=${date}&salbar_code=${salbar_code}`),
			post: (data) => instance.post(`/radiation-info/`, data)
		},
		air: {
			get: () => instance.get(`/air-road-geom/`)
		},
		workEmployee: {
			getByDate: (date, salbar_code) => instance.get(`/work-employee/by-date?date=${date}&salbar_code=${salbar_code}`)
		},
		dayInfo: {
			get: () => instance.get(`/day-info/`),
			getOne: (id) => instance.get(`/day-info/${id}`),
			post: (datas) => instance.post(`/day-info/`, datas)
		},
		dayWorkInfo: {
			get: () => instance.get(`/day-work-info/`),
			getOne: (id) => instance.get(`/day-work-info/${id}`),
			post: (datas) => instance.post(`/day-work-info/`, datas)
		},
		report: {
			getOperatorReportInfo: () => instance.get(`/report-info/operator/`)
		}

	}
}

export default useApi;
