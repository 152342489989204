import { createContext, useState, useEffect } from "react";

// hooks imports
import { useNavigate } from "react-router-dom";
import useApi from "@hooks/useApi";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {

    const [token, setToken] = useState(() =>
        localStorage.getItem("token")
            ? localStorage.getItem("token")
            : ''
    );


    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()
    const api = useApi().user

    async function logoutUser() {
        localStorage.removeItem("token")
        navigate('/login')
    }

    console.log(user)
    async function getUser() {
        if (!token) {
            navigate('login')
            return
        }
        setLoading(true)
        const { success, data } = await api.logged()
        if (success) {
            setUser(data)
        } else {
            navigate('/login')
        }
        setLoading(false)
    }


    const contextData = {
        loading,
        user,
        setUser,
        logoutUser,
    };
    useEffect(() => {
        getUser()
    }, [])


    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
};
